//@import "../../../../../../profiles/wcmc/themes/wcmc_base/scss/_variables";
//@import "../../../../../../profiles/wcmc/themes/wcmc_base/scss/_mixins";

@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";

/* =Sail Three Column
----------------------------------------------------------*/

.panel-sail-home {
  @include clearfix();
  position: relative;
}

.panel-sail-home #main-content {
  @include clearfix();
}

.panel-sail-home #sail-home-middle {
    max-width: 960px;
    padding: 0 10px;
    font-size: 1.8rem;
    @include breakpoint($sm) {
        padding: 0 30px;
    }
    margin: 3em auto;
//    @include box-sizing(border-box);
    
    h1 {
        margin-bottom: 20px;
//        text-align: center;
//        font-size: 3rem;
//        color: #000;
    }
  /*@include breakpoint($bp1) {
    width: 26.595%;
    margin-right: 40px;
    float: left;
  }*/
}

.panel-sail-home #sail-home-middle-video {
    @include clearfix();
    clear: both;
    max-width: 960px;
    padding: 0 10px;
    max-height: 350px;
    font-size: 1.8rem;
    margin: 1em auto;
//    @include box-sizing(border-box);
    
    iframe {
        height: 240px;
        @include breakpoint($sm) {
            height: 350px;
        }
    }
    
    h3 {
        color: #000;
        text-align: center;
        @include breakpoint($sm) {
            float: right;
            font-size: 3rem;
            width: 25%;
            padding: 4em 0.5em 0;
        }
    }
}


.panel-sail-home #sail-home-middle-grid {
    @include clearfix();
    clear: both;
    max-width: 960px;
    padding: 0 10px;
    margin: 0 auto 2em;
    position: relative;
    
    .home-about-us {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 1.8rem;
        
        li {
            text-align: center;
            border-bottom: 1px solid $wcm-border-gray;
            padding: 2em;
            
            &:last-child {
                border-bottom: none;
            }
        }
        /*a {
            color: #000;
        }*/
    }
    
    #middle-grid-left {
        @include breakpoint($sm) {
            float: left;
            width: 30%;
        }
        
    }
    #middle-grid-right {
        display: none;
        @include breakpoint($sm) {
            float: left;
            width: 40%;
            margin-left: 3%;
            clear: none;
            display: block;
        }
        
        .panel-pane {
            margin-right: 3%;
            margin-bottom: 0.7em; /*3%;*/
        }
        
        /*.panel-pane:nth-child(1){
//            width: 57%;
            margin-right: 3%;
            margin-bottom: 2%;
            float: left;
        }
        .panel-pane:nth-child(2){
//            width: 57%;
            margin-right: 3%;
            @include breakpoint($sm) {
//                margin-right: 5%;
//                width: 60%;
            }
            margin-bottom: 2%;
            float: left;
        }*/
    }
    #middle-grid-right2 {        
        display: none;
        @include breakpoint($sm) {
            width: 27%;
            float: left;
            display: block;
        }
        
        .panel-pane {
//            margin-right: 3%;
            margin-bottom: 0.7em;
        }
    }
}


.panel-sail-home #sail-home-middle-full {
//    max-width: 960px;
//    padding: 0 10px;
//    margin: 2em auto;
//    @include box-sizing(border-box);
}

.panel-sail-home #sail-home-left {
  @include breakpoint($sm) {
    width: 50%;
    float: left;
    padding-right: 30px;
  }
    
    .btn--wcm {
          padding: 10px 20px;
          display: inline-block;
            
          &:before {
            line-height: 2.5;
          }
      }
}

.panel-sail-home #sail-home-center {
    @include clearfix();
    max-width: 960px;
    padding: 0 10px;
    margin: 3em auto 4em;
}
/*.panel-sail-home #sail-home-center {
  @include breakpoint($bp1) {
    width: 34.0425%;
    float: left;
    margin-right: 10px;
  }
}*/

.panel-sail-home #sail-home-right {
  @include breakpoint($sm) {
//    width: 34.0425%;
    width: 50%;
    float: left;
  }
}

.panel-sail-home #sail-home-bottom {
    
    img {
        margin-bottom: 0;
    }
}

.panel-sail-home #sail-home-bottom-grid {
//    @include clearfix();
    max-width: 960px;
    padding: 0 10px;
    margin: 0 auto;
        
    #bottom-grid-left {
        @include breakpoint($sm) {
            float: left;
            width: 75%;
        }
        
        .panel-pane:nth-child(1){
            width: 65%;
            @include breakpoint($sm) {
                margin-right: 5%;
                width: 60%;
            }
            margin-bottom: 2%;
            float: right;
        }
        
        .panel-pane:nth-child(2), .panel-pane:nth-child(3) {
            width: 33%;
            margin-right: 2%;
            margin-bottom: 1em;
            float: left;
            img {
                margin-bottom: 0;
            }
        }
        
        .logged-in & {
            .panels-ipe-portlet-wrapper:nth-child(1) .panel-pane {
                width: 65%;
                @include breakpoint($sm) {
                    margin-right: 5%;
                    width: 60%;
                }
                margin-bottom: 2%;
                float: right;
            }

            .panels-ipe-portlet-wrapper:nth-child(2) .panel-pane, .panels-ipe-portlet-wrapper:nth-child(3) .panel-pane {
                width: 33%;
                margin-right: 2%;
                margin-bottom: 1em;
                float: left;
                img {
                    margin-bottom: 0;
                }
            }
        }
        
        /*.panel-pane:nth-child(1){
            width: 32%;
            margin-right: 3%;
            float: left;
        }
        .panel-pane:nth-child(2){
            width: 32%;
//            @include breakpoint($sm) {
                margin-right: 3%;
//                width: 60%;
//            }
//            margin-bottom: 2%;
            float: left;
        }*/
        /*.panel-pane:nth-child(2){
            width: 65%;
            @include breakpoint($sm) {
                margin-right: 5%;
                width: 60%;
            }
            margin-bottom: 2%;
            float: left;
        }*/
        /*.panel-pane:nth-child(3){
            width: 65%;
            @include breakpoint($sm) {
                margin-right: 5%;
                width: 60%;
            }
            margin-bottom: 2%;
            float: left;
        }*/
    }
    #bottom-grid-right {
        clear: both;
        @include breakpoint($sm) {
            float: left;
            width: 25%;
            clear: none;
        }
        
        .btn--wcm {
            padding: 10px 20px;
            
            &:before {
                line-height: 2.5;
            }
        }
    }
}
