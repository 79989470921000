/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Sail Three Column
----------------------------------------------------------*/
.panel-sail-home {
  position: relative; }
  .panel-sail-home:before, .panel-sail-home:after {
    content: " ";
    display: table; }
  .panel-sail-home:after {
    clear: both; }

.panel-sail-home #main-content:before, .panel-sail-home #main-content:after {
  content: " ";
  display: table; }

.panel-sail-home #main-content:after {
  clear: both; }

.panel-sail-home #sail-home-middle {
  max-width: 960px;
  padding: 0 10px;
  font-size: 1.8rem;
  margin: 3em auto;
  /*@include breakpoint($bp1) {
    width: 26.595%;
    margin-right: 40px;
    float: left;
  }*/ }
  @media screen and (min-width: 768px) {
    .panel-sail-home #sail-home-middle {
      padding: 0 30px; } }
  .panel-sail-home #sail-home-middle h1 {
    margin-bottom: 20px; }

.panel-sail-home #sail-home-middle-video {
  clear: both;
  max-width: 960px;
  padding: 0 10px;
  max-height: 350px;
  font-size: 1.8rem;
  margin: 1em auto; }
  .panel-sail-home #sail-home-middle-video:before, .panel-sail-home #sail-home-middle-video:after {
    content: " ";
    display: table; }
  .panel-sail-home #sail-home-middle-video:after {
    clear: both; }
  .panel-sail-home #sail-home-middle-video iframe {
    height: 240px; }
    @media screen and (min-width: 768px) {
      .panel-sail-home #sail-home-middle-video iframe {
        height: 350px; } }
  .panel-sail-home #sail-home-middle-video h3 {
    color: #000;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .panel-sail-home #sail-home-middle-video h3 {
        float: right;
        font-size: 3rem;
        width: 25%;
        padding: 4em 0.5em 0; } }

.panel-sail-home #sail-home-middle-grid {
  clear: both;
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto 2em;
  position: relative; }
  .panel-sail-home #sail-home-middle-grid:before, .panel-sail-home #sail-home-middle-grid:after {
    content: " ";
    display: table; }
  .panel-sail-home #sail-home-middle-grid:after {
    clear: both; }
  .panel-sail-home #sail-home-middle-grid .home-about-us {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    /*a {
            color: #000;
        }*/ }
    .panel-sail-home #sail-home-middle-grid .home-about-us li {
      text-align: center;
      border-bottom: 1px solid #dddddd;
      padding: 2em; }
      .panel-sail-home #sail-home-middle-grid .home-about-us li:last-child {
        border-bottom: none; }
  @media screen and (min-width: 768px) {
    .panel-sail-home #sail-home-middle-grid #middle-grid-left {
      float: left;
      width: 30%; } }
  .panel-sail-home #sail-home-middle-grid #middle-grid-right {
    display: none;
    /*.panel-pane:nth-child(1){
//            width: 57%;
            margin-right: 3%;
            margin-bottom: 2%;
            float: left;
        }
        .panel-pane:nth-child(2){
//            width: 57%;
            margin-right: 3%;
            @include breakpoint($sm) {
//                margin-right: 5%;
//                width: 60%;
            }
            margin-bottom: 2%;
            float: left;
        }*/ }
    @media screen and (min-width: 768px) {
      .panel-sail-home #sail-home-middle-grid #middle-grid-right {
        float: left;
        width: 40%;
        margin-left: 3%;
        clear: none;
        display: block; } }
    .panel-sail-home #sail-home-middle-grid #middle-grid-right .panel-pane {
      margin-right: 3%;
      margin-bottom: 0.7em;
      /*3%;*/ }
  .panel-sail-home #sail-home-middle-grid #middle-grid-right2 {
    display: none; }
    @media screen and (min-width: 768px) {
      .panel-sail-home #sail-home-middle-grid #middle-grid-right2 {
        width: 27%;
        float: left;
        display: block; } }
    .panel-sail-home #sail-home-middle-grid #middle-grid-right2 .panel-pane {
      margin-bottom: 0.7em; }

@media screen and (min-width: 768px) {
  .panel-sail-home #sail-home-left {
    width: 50%;
    float: left;
    padding-right: 30px; } }

.panel-sail-home #sail-home-left .btn--wcm {
  padding: 10px 20px;
  display: inline-block; }
  .panel-sail-home #sail-home-left .btn--wcm:before {
    line-height: 2.5; }

.panel-sail-home #sail-home-center {
  max-width: 960px;
  padding: 0 10px;
  margin: 3em auto 4em; }
  .panel-sail-home #sail-home-center:before, .panel-sail-home #sail-home-center:after {
    content: " ";
    display: table; }
  .panel-sail-home #sail-home-center:after {
    clear: both; }

/*.panel-sail-home #sail-home-center {
  @include breakpoint($bp1) {
    width: 34.0425%;
    float: left;
    margin-right: 10px;
  }
}*/
@media screen and (min-width: 768px) {
  .panel-sail-home #sail-home-right {
    width: 50%;
    float: left; } }

.panel-sail-home #sail-home-bottom img {
  margin-bottom: 0; }

.panel-sail-home #sail-home-bottom-grid {
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto; }
  .panel-sail-home #sail-home-bottom-grid #bottom-grid-left {
    /*.panel-pane:nth-child(1){
            width: 32%;
            margin-right: 3%;
            float: left;
        }
        .panel-pane:nth-child(2){
            width: 32%;
//            @include breakpoint($sm) {
                margin-right: 3%;
//                width: 60%;
//            }
//            margin-bottom: 2%;
            float: left;
        }*/
    /*.panel-pane:nth-child(2){
            width: 65%;
            @include breakpoint($sm) {
                margin-right: 5%;
                width: 60%;
            }
            margin-bottom: 2%;
            float: left;
        }*/
    /*.panel-pane:nth-child(3){
            width: 65%;
            @include breakpoint($sm) {
                margin-right: 5%;
                width: 60%;
            }
            margin-bottom: 2%;
            float: left;
        }*/ }
    @media screen and (min-width: 768px) {
      .panel-sail-home #sail-home-bottom-grid #bottom-grid-left {
        float: left;
        width: 75%; } }
    .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panel-pane:nth-child(1) {
      width: 65%;
      margin-bottom: 2%;
      float: right; }
      @media screen and (min-width: 768px) {
        .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panel-pane:nth-child(1) {
          margin-right: 5%;
          width: 60%; } }
    .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panel-pane:nth-child(2), .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panel-pane:nth-child(3) {
      width: 33%;
      margin-right: 2%;
      margin-bottom: 1em;
      float: left; }
      .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panel-pane:nth-child(2) img, .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panel-pane:nth-child(3) img {
        margin-bottom: 0; }
    .logged-in .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panels-ipe-portlet-wrapper:nth-child(1) .panel-pane {
      width: 65%;
      margin-bottom: 2%;
      float: right; }
      @media screen and (min-width: 768px) {
        .logged-in .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panels-ipe-portlet-wrapper:nth-child(1) .panel-pane {
          margin-right: 5%;
          width: 60%; } }
    .logged-in .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panels-ipe-portlet-wrapper:nth-child(2) .panel-pane, .logged-in .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panels-ipe-portlet-wrapper:nth-child(3) .panel-pane {
      width: 33%;
      margin-right: 2%;
      margin-bottom: 1em;
      float: left; }
      .logged-in .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panels-ipe-portlet-wrapper:nth-child(2) .panel-pane img, .logged-in .panel-sail-home #sail-home-bottom-grid #bottom-grid-left .panels-ipe-portlet-wrapper:nth-child(3) .panel-pane img {
        margin-bottom: 0; }
  .panel-sail-home #sail-home-bottom-grid #bottom-grid-right {
    clear: both; }
    @media screen and (min-width: 768px) {
      .panel-sail-home #sail-home-bottom-grid #bottom-grid-right {
        float: left;
        width: 25%;
        clear: none; } }
    .panel-sail-home #sail-home-bottom-grid #bottom-grid-right .btn--wcm {
      padding: 10px 20px; }
      .panel-sail-home #sail-home-bottom-grid #bottom-grid-right .btn--wcm:before {
        line-height: 2.5; }
